import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

interface SVGBackgroundProps {
  opacity?: number;
}

export const WavyBackground: React.FC<SVGBackgroundProps> = ({ opacity = 0.05 }) => {
  const color = useColorModeValue('teal.500', 'teal.200');
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={-1}
      opacity={opacity}
    >
      <svg width="100%" height="100%" viewBox="0 0 1440 800" preserveAspectRatio="xMidYMid slice">
        <path d="M0,800 C420,780 720,620 1440,600 V0 H0 Z" fill={color} />
        <path d="M0,800 C300,750 720,550 1440,500 V0 H0 Z" fill={color} fillOpacity="0.5" />
      </svg>
    </Box>
  );
};

export const CircleBackground: React.FC<SVGBackgroundProps> = ({ opacity = 0.05 }) => {
  const color = useColorModeValue('purple.500', 'purple.200');
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={-1}
      opacity={opacity}
    >
      <svg width="100%" height="100%" viewBox="0 0 1000 1000" preserveAspectRatio="xMidYMid slice">
        <circle cx="500" cy="500" r="400" fill="none" stroke={color} strokeWidth="100" strokeDasharray="50 30" />
        <circle cx="500" cy="500" r="300" fill="none" stroke={color} strokeWidth="50" strokeDasharray="20 20" />
      </svg>
    </Box>
  );
};

export const DotsBackground: React.FC<SVGBackgroundProps> = ({ opacity = 0.05 }) => {
  const color = useColorModeValue('blue.500', 'blue.200');
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={-1}
      opacity={opacity}
    >
      <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
        <defs>
          <pattern id="dots" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <circle cx="2" cy="2" r="1" fill={color} />
          </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#dots)" />
      </svg>
    </Box>
  );
};

export const TriangleBackground: React.FC<SVGBackgroundProps> = ({ opacity = 0.05 }) => {
  const color = useColorModeValue('orange.500', 'orange.200');
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={-1}
      opacity={opacity}
    >
      <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
        <defs>
          <pattern id="triangles" width="10" height="10" patternUnits="userSpaceOnUse">
            <path d="M0,0 L5,10 L10,0 Z" fill={color} />
          </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#triangles)" />
      </svg>
    </Box>
  );
};