import React from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  IconButton,
  useColorModeValue,
  Divider,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

const socialLinks = [
  { label: "Twitter", icon: FaTwitter, url: "https://twitter.com" },
  { label: "GitHub", icon: FaGithub, url: "https://github.com" },
  { label: "LinkedIn", icon: FaLinkedin, url: "https://linkedin.com" },
];

const footerLinks = [
  { label: "Home", path: "/" },
  { label: "About", path: "/about" },
  { label: "Privacy Policy", path: "/privacy-policy" },
  { label: "Terms of Service", path: "/terms-of-service" },
];

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.400");
  const linkHoverColor = useColorModeValue("teal.600", "teal.300");
  const iconBg = useColorModeValue("gray.700", "gray.200");
  const iconHoverBg = useColorModeValue("gray.800", "gray.300");

  return (
    <Box
      bg={bgColor}
      color={textColor}
      borderTopWidth={1}
      borderStyle={"solid"}
      borderColor={borderColor}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <VStack spacing={8}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            w="full"
            wrap="wrap"
          >
            <Text textAlign="center" mb={{ base: 4, md: 0 }}>
              © {currentYear} Heighten Studios LLC. All rights reserved
            </Text>
            <Stack direction={"row"} spacing={6} justify="center" wrap="wrap">
              {footerLinks.map((link) => (
                <Link
                  key={link.label}
                  as={RouterLink}
                  to={link.path}
                  color={textColor}
                  _hover={{ color: linkHoverColor }}
                  fontWeight="medium"
                >
                  {link.label}
                </Link>
              ))}
            </Stack>
          </Flex>

          <Divider />

          {/* <Stack direction={"row"} spacing={6} justify="center">
            {socialLinks.map((social) => (
              <IconButton
                key={social.label}
                aria-label={social.label}
                icon={<social.icon />}
                size="md"
                color={"white"}
                bg={iconBg}
                _hover={{
                  bg: iconHoverBg,
                }}
                onClick={() => window.open(social.url, "_blank")}
              />
            ))}
          </Stack> */}
        </VStack>
      </Container>
    </Box>
  );
};

export default React.memo(Footer);