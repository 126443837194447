import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import App from "./components/App";
import theme from "./components/Theme/Theme";

const AppWithAuth = withAuthenticator(App, {
  signUpAttributes: ["email", "given_name", "family_name"],
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter>
        <AppWithAuth />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
