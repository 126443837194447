import { extendTheme, ThemeConfig, ThemeComponentProps } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: ThemeComponentProps) => ({
      body: {
        bg: props.colorMode === "dark" ? "gray.800" : "white",
        color: props.colorMode === "dark" ? "white" : "gray.800",
      },
    }),
  },
  colors: {
    brand: {
      50: "#E6FFFA",
      100: "#B2F5EA",
      200: "#81E6D9",
      300: "#4FD1C5",
      400: "#38B2AC",
      500: "#319795",
      600: "#2C7A7B",
      700: "#285E61",
      800: "#234E52",
      900: "#1D4044",
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
      },
      variants: {
        solid: (props: ThemeComponentProps) => ({
          bg: props.colorMode === "dark" ? "brand.200" : "brand.500",
          color: props.colorMode === "dark" ? "gray.800" : "white",
          _hover: {
            bg: props.colorMode === "dark" ? "brand.300" : "brand.600",
          },
        }),
      },
    },
    Heading: {
      baseStyle: (props: ThemeComponentProps) => ({
        color: props.colorMode === "dark" ? "white" : "gray.800",
      }),
    },
    Text: {
      baseStyle: (props: ThemeComponentProps) => ({
        color: props.colorMode === "dark" ? "gray.200" : "gray.700",
      }),
    },
  },
});

export default theme;