import React from 'react';
import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem, useColorModeValue } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { TriangleBackground } from '../Assets/SvgBackgrounds';

const termsOfServiceContent = `
# Terms of Service

Last Updated: July 1, 2024

By using www.coopreader.com ("Service"), you agree to these Terms of Service ("Terms"). If you disagree, do not use the Service.

## 1. Use of Service

You may use the Service only for lawful purposes. You are solely responsible for your account and all activity under it.

## 2. Prohibited Activities

You must not: \n
a) Violate any laws or regulations
b) Infringe on intellectual property rights
c) Attempt to gain unauthorized access to the Service
d) Use the Service to harm others or damage our reputation

## 3. Intellectual Property

All content and functionality of the Service are the exclusive property of www.coopreader.com

## 4. Disclaimer of Warranties

The Service is provided "AS IS" without any warranties, express or implied.

## 5. Limitation of Liability

To the fullest extent permitted by law, Heighten Studios shall not be liable for any damages arising out of or in connection with the use of the Service.

## 6. Indemnification

You agree to indemnify and hold harmless Heighten Studios from any claims, damages, or expenses arising from your use of the Service or violation of these Terms.

## 7. Termination

We may terminate or suspend your account at any time, without prior notice or liability, for any reason.

## 8. Governing Law

These Terms are governed by the laws of [Your Country], without regard to conflict of law principles.

## 9. Changes to Terms

We may modify these Terms at any time. Your continued use of the Service after changes constitutes acceptance of those changes.

## 10. Entire Agreement

These Terms constitute the entire agreement between you and Heighten Studios regarding the Service.
`;

const TermsOfService: React.FC = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const headingColor = useColorModeValue("gray.800", "white");

  return (
    <Box bg={bgColor} color={textColor} minHeight="calc(100vh - 64px)" py={12} position="relative">
      <TriangleBackground opacity={0.02} />
      <Container maxW="container.md">
        <VStack spacing={8} align="stretch">
          <ReactMarkdown
            components={{
              h1: ({node, ...props}) => <Heading as="h1" size="2xl" mb={6} color={headingColor} {...props} />,
              h2: ({node, ...props}) => <Heading as="h2" size="xl" mt={8} mb={4} color={headingColor} {...props} />,
              p: ({node, ...props}) => <Text mb={4} fontSize="lg" lineHeight="tall" {...props} />,
              ul: ({node, ...props}) => <UnorderedList mb={4} spacing={2} pl={4} {...props} />,
              li: ({node, ...props}) => <ListItem fontSize="lg" {...props} />,
            }}
          >
            {termsOfServiceContent}
          </ReactMarkdown>
        </VStack>
      </Container>
    </Box>
  );
};

export default TermsOfService;