import React from 'react';
import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem, useColorModeValue } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const privacyPolicyContent = `
# Privacy Policy

Last updated: July 1, 2024

## 1. Introduction

Welcome to Coop Reader. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.

## 2. Information We Collect

We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:

- Data: username, email address or similar identifier.

This information is collected solely for the purpose of user authentication and displaying your username on your personal profile within the Site.

## 3. How We Use Your Information

Your information is used exclusively for:

- Authenticating your access to the Site
- Displaying your username on your personal profile (not visible to other users)

We do not use your information for any other purposes, including marketing or sharing with third parties.

## 4. Data Security

We use AWS Cognito, a secure and reliable service, for user authentication. While we implement reasonable security measures, no method of transmission over the Internet or electronic storage is 100% secure.

## 5. Your Rights and Responsibilities

You have the right to:

- Access your personal information
- Correct inaccurate or incomplete information
- Delete your account and associated data

To exercise these rights, please contact us using the information provided in the "Contact Us" section.

## 6. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.

## 7. Disclaimer of Liability

While we take reasonable measures to protect your personal information, we cannot guarantee the security of data transmitted over the Internet or stored electronically. You acknowledge that any information you transmit or store through our Site is done at your own risk.
`;

const PrivacyPolicy: React.FC = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const headingColor = useColorModeValue("gray.800", "white");

  return (
    <Box bg={bgColor} color={textColor} minHeight="calc(100vh - 64px)" py={12}>
      <Container maxW="container.md">
        <VStack spacing={8} align="stretch">
          <ReactMarkdown
            components={{
              h1: ({node, ...props}) => <Heading as="h1" size="2xl" mb={6} color={headingColor} {...props} />,
              h2: ({node, ...props}) => <Heading as="h2" size="xl" mt={8} mb={4} color={headingColor} {...props} />,
              p: ({node, ...props}) => <Text mb={4} fontSize="lg" lineHeight="tall" {...props} />,
              ul: ({node, ...props}) => <UnorderedList mb={4} spacing={2} pl={4} {...props} />,
              li: ({node, ...props}) => <ListItem fontSize="lg" {...props} />,
            }}
          >
            {privacyPolicyContent}
          </ReactMarkdown>
        </VStack>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;