import React, { lazy, Suspense, createContext, useContext } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { AuthUser } from "aws-amplify/auth";
import { UseAuthenticator } from "@aws-amplify/ui-react-core";
import "@aws-amplify/ui-react/styles.css";
import config from "../amplifyconfiguration.json";
import Header from "./Header/Header";
import ErrorBoundary from "./ErrorBoundary";
import "../styles/global.css";
import Footer from "./Footer/Footer";
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import TermsOfService from "./Footer/TermsOfService";
import { Article } from "../types/Articles/Article";

// Lazy load components
const Home = lazy(() => import("./Home/Home"));
const About = lazy(() => import("./About/About"));
const ProfilePage = lazy(() => import("./Profile/Profile"));
const ArticleList = lazy(() => import("./Article/ArticleList"));
const ArticleDetail = lazy(() => import("./Article/ArticleDetail"));

Amplify.configure(config);

interface AppProps {
  signOut?: UseAuthenticator["signOut"];
  user?: AuthUser;
}

// Mock articles data
const articles: Article[] = [
  {
    id: '1',
    title: 'The Future of AI: Trends to Watch in 2024',
    content: [
      { "type": "paragraph", "text": "First paragraph." },
      { "type": "image", "url": "https://example.com/image.jpg", "caption": "An image caption" },
      {"type" : "code", "code": "test code"},
      { "type": "paragraph", "text": "Second paragraph." }
    ],
    author: 'Dr. Emily Chen',
    createdAt: '2024-07-01T00:00:00Z',
    updatedAt: '2024-07-01T00:00:00Z',
    imageUrl: '/api/placeholder/1200/400',
    category: 'Technology'
  },
  // Add more mock articles here...
];

// Create a context for articles
export const ArticlesContext = createContext<Article[]>([]);

// Wrapper component for ArticleDetail to handle article fetching
const ArticleDetailWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const articles = useContext(ArticlesContext);
  const article = articles.find(a => a.id === id);

  if (!article) {
    return <div>Article not found</div>;
  }

  return <ArticleDetail article={article} />;
};

const fetchArticles = async (page: number, limit: number) => {
  // In a real application, this would be an API call
  const start = (page - 1) * limit;
  const end = start + limit;
  return articles.slice(start, end);
};

const App: React.FC<AppProps> = React.memo(({ signOut, user }) => {
  console.log("Articles in App:", articles); // Debug log

  return (
    <ArticlesContext.Provider value={articles}>
      <ErrorBoundary>
        <Header signOut={signOut} user={user} />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/articles" element={<ArticleList fetchArticles={fetchArticles} />} />
            <Route path="/articles/:id" element={<ArticleDetailWrapper />} />
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </Suspense>
        <Footer />
      </ErrorBoundary>
    </ArticlesContext.Provider>
  );
});

const AppWithAuth: React.FC = () => (
  <Authenticator>
    {({ signOut, user }) => <App signOut={signOut} user={user} />}
  </Authenticator>
);

export default AppWithAuth;