import React, { useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { AuthUser } from "aws-amplify/auth";
import { UseAuthenticator } from "@aws-amplify/ui-react-core";
import {
  Box,
  Flex,
  Text,
  HStack,
  Avatar,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
  DrawerHeader,
  IconButton,
  Container,
  useColorMode,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";

interface HeaderProps extends WithAuthenticatorProps {
  signOut?: UseAuthenticator["signOut"];
  user?: AuthUser;
}

const HeaderLink: React.FC<{ to: string; label: string; currentPage: string; onClose?: () => void }> = React.memo(({
  to,
  label,
  currentPage,
  onClose,
}) => {
  const isSelected = currentPage === to;
  const linkColor = useColorModeValue("gray.600", "gray.300");
  const linkHoverColor = useColorModeValue("teal.500", "teal.300");
  const selectedColor = useColorModeValue("teal.600", "teal.300");

  return (
    <Box mb={onClose ? 2 : 0}>
      <Link to={to} onClick={onClose}>
        <Text
          fontSize="sm"
          fontWeight="medium"
          color={isSelected ? selectedColor : linkColor}
          _hover={{ color: linkHoverColor }}
          transition="color 0.2s"
          borderBottom={isSelected ? "2px solid" : "none"}
          borderColor={selectedColor}
          pb={1}
        >
          {label}
        </Text>
      </Link>
    </Box>
  );
});

const Header: React.FC<HeaderProps> = React.memo(({ signOut, user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const currentPage = location.pathname;
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const handleToggleMenu = useCallback(() => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  }, [isOpen, onClose, onOpen]);

  const memoizedLinks = useMemo(() => (
    <>
      <HeaderLink to="/home" label="Home" currentPage={currentPage} />
      <HeaderLink to="/articles" label="Articles" currentPage={currentPage} />
      <HeaderLink to="/about" label="About Us" currentPage={currentPage} />
    </>
  ), [currentPage]);

  const memoizedMobileLinks = useMemo(() => (
    <>
      <HeaderLink to="/home" label="Home" currentPage={currentPage} onClose={onClose} />
      <HeaderLink to="/articles" label="Articles" currentPage={currentPage} onClose={onClose} />
      <HeaderLink to="/about" label="About Us" currentPage={currentPage} onClose={onClose} />
      <HeaderLink to="/profile" label="Profile" currentPage={currentPage} onClose={onClose} />
    </>
  ), [currentPage, onClose]);

  return (
    <Box as="header" bg={bg} color={color} boxShadow="sm" borderBottom="1px" borderColor={borderColor}>
      <Container maxW="container.xl">
        <Flex py={2} align="center">
          <Link to="/home">
            <Box as="svg" width="32px" height="32px" viewBox="0 0 32 32" mr={2}>
              <rect width="32" height="32" fill="#319795" rx="5" ry="5" />
              <polygon points="24,32 32,32 32,24" fill="#CCCCCC"/>
              <rect x="4" y="10" width="24" height="3" fill={color} rx="1.5" ry="1.5"/>
              <rect x="4" y="16" width="24" height="3" fill={color} rx="1.5" ry="1.5" />
              <rect x="4" y="22" width="20" height="3" fill={color} rx="1.5" ry="1.5"/>
              <circle cx="24" cy="8" r="6" fill="#FFD700"/>
            </Box>
          </Link>
          {/* <Text fontSize="lg" fontWeight="bold" lineHeight="32px">
            Coop Reader
          </Text> */}
          <HStack display={{ base: "none", md: "flex" }} spacing={4} ml={8}>
            {memoizedLinks}
          </HStack>
          <Flex ml="auto" align="center" gap={2}>
            <IconButton
              aria-label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`}
              icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
              variant="ghost"
              size="sm"
            />
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Avatar size="sm" name={user?.signInDetails?.loginId} bg="teal.500" />}
                variant="ghost"
                aria-label="User menu"
                size="sm"
              />
              <MenuList>
                <MenuItem as={Link} to="/profile">Profile</MenuItem>
                <MenuItem onClick={signOut}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
            <IconButton
              aria-label={isOpen ? "Close Menu" : "Open Menu"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              variant="ghost"
              display={{ base: "flex", md: "none" }}
              onClick={handleToggleMenu}
              size="sm"
            />
          </Flex>
        </Flex>
      </Container>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent bg={bg} color={color}>
          <DrawerHeader borderBottomWidth="1px" borderColor={borderColor}>
            Menu
          </DrawerHeader>
          <DrawerBody>
            <Flex direction="column" align="center" justify="center" h="100%">
              {memoizedMobileLinks}
              <Box onClick={signOut} mt={4} width="full">
                <Text
                  fontSize="sm"
                  fontWeight="medium"
                  color="red.500"
                  textAlign="center"
                  cursor="pointer"
                  _hover={{ textDecoration: "underline" }}
                >
                  Sign Out
                </Text>
              </Box>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
});

export default Header;